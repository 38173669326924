.WeekScheduler {
    margin: 20px;
}

    .WeekScheduler h1 {
        font-size: 24px;
        margin-bottom: 10px;
    }

    .WeekScheduler table {
        border-collapse: collapse;
        width: 100%;
    }

    .WeekScheduler th,
    .WeekScheduler td {
        padding: 10px;
        text-align: center;
        border: 1px solid #ccc;
    }

    .WeekScheduler input[type="number"] {
        width: 60px;
        padding: 5px;
        text-align: center;
        border: 1px solid #ccc;
    }

        .WeekScheduler input[type="number"]:focus {
            outline: none;
            border-color: #333;
        }


.dropdown {
    margin-left: 2%;
}