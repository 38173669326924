.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}


* {
	margin: 0;
	padding: 0;
}

.text-box .section {
	padding: 2% 2%;
	margin: 1% 1%;

}

.layout {
	min-height: 100vh;
	width: 100%;
	background-position: center;
	background-size: cover;
	position: relative;
}

#home {
	background-image: linear-gradient(rgba(160, 160, 160, 0.5), rgba(160, 160, 160, 0.5)), url(pages/image/thumbnail_image1.jpg);
}

#about {
	background-image: linear-gradient(rgba(160, 160, 160, 0.5), rgba(160, 160, 160, 0.5)), url(pages/image/aboutBackground.jpg);
}

#price {
	background-image: linear-gradient(rgba(160, 160, 160, 0.5), rgba(160, 160, 160, 0.5)), url(pages/image/priceBackground.jpg);
}

#service {
	background-image: linear-gradient(rgba(160, 160, 160, 0.5), rgba(160, 160, 160, 0.5)), url(pages/image/unknown.jpg);
}

header {
	top: 0;
	z-index: 1;
	position: sticky;
	display: flex
}

.center {
	border: 5px solid;
	margin: auto;
	width: 50%;
	padding: 10px;
}

.head {
	height: 80px;
	width: 100%;
	background-color: rgb(239, 237, 237);
	display: flex;
	align-items: center;
	justify-content: center;
}

.head img {
	width: 65px;
	height: 65px;
	margin: 5px 5px
}

.title {
	color: #f44336;
	font-size: 40px;
	align-items: center;
	margin: 5px 10px;
	position: absolute;
	float: left;
	top: 10px
}

nav {
	position: absolute;
	right: 10px;
	top: 10px;
	display: flex;
	padding: 1%;
	justify-content: space-between;
	align-items: center;
}


.nav-profile {
	color: #f44336;
	text-decoration: none;
	font-size: 18px;
	right: -15px;
}

.nav-links {
	flex: 1;
	display: inline-block;
	text-align: right;
}

.nav-links ul li {
	list-style: none;
	display: inline-block;
	padding: 4px 6px;
	position: relative;
}

.nav-links ul li a {
	color: #f44336;
	text-decoration: none;
	font-size: 18px;
}

.nav-links ul li.clickable::after {
	content: '';
	width: 0%;
	height: 2px;
	background: #f44336;
	display: block;
	margin: auto;
	transition: 0.5s;
}

.nav-links ul li:hover::after {
	width: 100%;
}

#page-container {
	position: relative;
	min-height: 100vh;
}

#content-wrap {
	padding-bottom: 300px
}

.footer {
	text-align: center;
	background-color: rgba(211, 211, 211, 1);

	position: absolute;
	bottom: 0;
	height: 360px;
	width: 100%;
}

.footer-contact {
	display: inline-block;
	color: rgb(66, 66, 66);
	text-align: left;
	padding-left: 180px;
	padding-right: 90px;
	padding-top: 20px;
	top: 15px;
	position: relative
}

.footer-references {
	display: inline-block;
	color: rgb(66, 66, 66);
	text-align: left;
	padding-left: 90px;
	padding-right: 180px;
	padding-top: 20px;
	position: relative
}

.footer-about {
	display: inline-block;
	color: rgb(66, 66, 66);
	text-align: left;
	word-wrap: break-word;
	padding-left: 20px;
	padding-right: 20px;
	padding-top: 20px;
	width: 575px;
	height: 60px;
	font-size: 10pt;
	position: relative;
}

.footer-link {
	color: rgb(50, 50, 50);
	font-weight: lighter;
	text-decoration: none;
}

.footer-link:hover {
	color: #f44336;
	font-weight: lighter;
	text-decoration: underline;
}

.footer-copyright {
	text-align: center;
	color: black;
	padding-top: 30px;
	padding-bottom: 10px;
	font-size: 13px;
	margin-bottom: 0;
}

.footer-socialmedia {
	display: inline-block;
	font-size: 15px;
	position: relative;
}

.fa-facebook {
	background: #3B5998;
	color: white;
	padding: 20px;
	width: 10px;
	border-radius: 25%;
	text-align: center;
	margin: 5px 2px;
}

.fa-linkedin {
	background: #007bb5;
	color: white;
	padding: 20px;
	width: 10px;
	border-radius: 25%;
	text-align: center;
	margin: 5px 2px;
}


.footer-img {
	width: 80px;
	height: 80px;
	display: block;
	margin-left: auto;
	margin-right: auto;
	position: relative;
	top: 25px
}

.contact-information {
	width: 90%;
	color: #fff;
	position: absolute;
	top: 20%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
	font-size: 16px;
}

.contact-information h1 {
	font-size: 28 px;
	text-align: center;
}


.contact-location {
	width: 90%;
	color: #fff;
	position: absolute;
	top: 63%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
	font-size: 13px;
}

.contact-location h1 {
	font-size: 28 px;
	text-align: center;
}

.contact-location iframe {
	display: inline-block;
}


.text-box {
	color: #fff;
	position: relative;
	display: grid;
	width: 90%;
	top: 50%;
	left: 50%;
	transform: translate(-50%, 25%);
	text-align: center;
}

.text-box h1 {
	font-size: 62px;
}

.text-box p {
	margin: 10px 0 40px;
	font-size: 14px;
	color: #fff;
}

.adPrice-btn {
	display: inline-block;
	text-decoration: none;
	color: #fff;
	border: 1px solid #fff;
	padding: 12px 34px;
	font-size: 13px;
	background: transparent;
	position: relative;
	cursor: pointer;
}

.adPrice-btn:hover {
	display: inline-block;
	text-decoration: none;
	color: #fff;
	border: 1px solid #fff;
	padding: 12px 34px;
	font-size: 13px;
	background: transparent;
	position: relative;
	cursor: pointer;
}

nav .fa {
	display: none;
}

@media(max-width: 1300px) {
	.topBanner {
		text-align: center;
		padding: 15px 0;
		position: relative;
		display: block;
		color: rgb(0, 0, 0)
	}

	.topBanner img {
		position: absolute;
		width: 65px;
		height: 65px;
		left: 10px;
		top: 7.5px
	}


	.text-box h1 {
		font-size: 20px;
	}

	.nav-links ul li {
		display: block;
	}

	.nav-links {
		position: absolute;
		background: #f44336;
		height: 100vh;
		width: 200px;
		top: 0;
		right: -220px;
		text-align: left;
		z-index: 2;
		transition: 1s;
		overflow-x: hidden;
	}

	nav .fa {
		display: block;
		color: #fff;
		margin: 10px;
		font-size: 22px;
		cursor: pointer;
	}

	.nav-links ul {
		padding: 30px;
	}

	.foot {
		height: 100%;
		width: 100%;
		position: absolute;
		background-color: rgba(0, 0, 0, 1);
	}

	.footer-contact {
		display: inline-block;
		color: white;
		text-align: left;
		padding-left: 10px;
		padding-right: 10px;
		padding-top: 20px;
		position: relative
	}

	.footer-references {
		display: inline-block;
		color: white;
		text-align: left;
		padding-left: 10px;
		padding-right: 10px;
		padding-top: 20px;
		position: relative
	}

	.footer-about {
		display: inline-block;
		color: white;
		text-align: center;
		word-wrap: break-word;
		padding-left: 10px;
		padding-right: 10px;
		padding-top: 20px;
		position: relative
	}
}

@media screen {}


.button {
	background-color: white;
	border: 2px solid #f44336;
	color: black;
	padding: 10px 32px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 16px;
	margin: 4px 2px;
	transition-duration: 0.4s;
	cursor: pointer;
}

.button:hover {
	background-color: #f44336;
	color: white;
	border: 2px solid #ffffff;
}





















.home_content {
	text-align: center;
}

.home_countent .text {
	font-size: 25px;
	font-weight: 500;
	color: #1d1b31;
	margin: 12px;
}

tr:nth-of-type(odd) {
	background: #eee;
}

tr:nth-of-type(even) {
	background: #eee;
}

th {
	background: #f44336;
	color: white;
	font-weight: bold;
}

table {
	width: 500px;
	border-collapse: collapse;
	margin: 50px auto;
}

td,
th {
	padding: 10px;
	border: 1px solid #ccc;
	text-align: left;
	font-size: 18px;
}


.arrow {
	border: solid white;
	border-width: 0 3px 3px 0;
	display: inline-block;
	padding: 3px;
}

.right {
	transform: rotate(-45deg);
	-webkit-transform: rotate(-45deg);
	float: left
}

.left {
	transform: rotate(135deg);
	-webkit-transform: rotate(135deg);
	float: right;
	margin-right: 4vh;
}

.btn-arrow {
	background-color: transparent;
	background-repeat: no-repeat;
	border: none;
	cursor: pointer;
	overflow: hidden;
	outline: none;
	flex: 1;
	margin-left: 4vh;
}

.date-text {
	max-width: 100%;
}

.date-selector {
	display: flex;
}

.employee-select {
	appearance: none;
	outline: 0;
	background-image: none;
	width: 32vh;
	height: 4vh;
	color: black;
	cursor: pointer;
	border: 8px solid #fff;
	border-radius: 3px;
}

.employee-text {
	margin: 0 8px 32px 0;
}

.profile-text {
	position: absolute;
	top: 5vh;
	left: 50%;
	text-align: right;
}